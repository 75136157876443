.loginCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 169px;
  padding: 16px 24px;
  cursor: pointer;
  background: linear-gradient(90deg, #1c70ee -32.63%, #78b7ff 115.83%);
  border-radius: 16px;

  .cardTop {
    .cardTitle {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: #fff;
    }

    .cardDesc {
      margin-top: 4px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #fff;
    }
  }

  .cardBottom {
    width: 100%;
    margin-top: 12px;

    .loginButton {
      display: inline-flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      padding: 7px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #000;
      background: #fff;
      border-radius: 8px;
    }
  }
}

.loginCardRow {
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 6px);
}
