@import 'styles/mixins';
@import 'styles/variable';

.container {
  width: 100%;
  height: auto;
  padding: 24px 10px 36px;
  background: #F4F5FE;
  border-radius: 16px;

  .excellentResume {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    text-align: center;
  }

  .openTheDoorToSuccess {
    padding: 4px 0 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

  img {
    display: block;
    width: 100%;
  }

  .button {
    display: flex;
    justify-content: center;
    padding-top: 38px;
  }
}