.pcCommButton {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  overflow: hidden;
  font-family: "SF Pro Display";
  font-style: normal;
  cursor: pointer;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.loadingWrapper {
  display: inline-flex;
  animation: loadingCircle 1s infinite linear;

  svg {
    width: 1em;
    height: 1em;
  }
}

.size48 {
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 12px;
}

.size44 {
  height: 44px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border-radius: 12px;
}

.size40 {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 10px;
}

.size36 {
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
}

.size32 {
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}

.loadingBtn {
  pointer-events: none;
  opacity: 0.7;
}

.primaryBtn {
  color: #fff;
  background: #1c70ee;

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    background: #0d6eff;
  }
}

.whiteOutlineBtn {
  color: #000;
  border: 1px solid #EAEAEA;

  &.size48,
  &.size44,
  &.size40 {
    border-width: 1.2px;
  }

  &:not(&.disabled, .loadingBtn):hover {
    color: #0d6eff;
    border-color: #0d6eff;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
}

.primaryOutlineBtn {
  color: #1c70ee;
  border: 1px solid #1c70ee;

  &.size48,
  &.size44,
  &.size40 {
    border-width: 1.2px;
  }

  &:not(&.disabled, .loadingBtn):hover {
    color: #0d6eff;
    border-color: #0d6eff;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
}

.blackBtn {
  color: #fff;
  background: #000;

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.whiteBtn {
  color: #000;
  background: #fff;
  border: 1px solid transparent;

  &.disabled {
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    background: #f8f9fa;
  }

  &:not(&.disabled, .loadingBtn):hover {
    border: 1px solid rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}

.grayBtn {
  color: #000;
  background: #f8f9fa;

  &.disabled {
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    background: #f8f9fa;
  }

  &:not(&.disabled, .loadingBtn):hover {
    background: #f3f5f6;
  }
}

.whiteOpacityBtn {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid transparent;

  &.disabled {
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    background: #f8f9fa;
  }

  &:not(&.disabled, .loadingBtn):hover {
    border-color: rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}

.primaryTextBtn {
  color: #1c70ee;

  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  &:not(&.disabled, .loadingBtn):hover {
    color: #0d6eff;
  }
}

.whiteBoxShadowBtn {
  color: #000;
  background: #fff;
  border: 1.2px solid #dbdfe4;

  &.size48,
  &.size44 {
    /* Normal Shadow Styles/Normal Shadow-md */
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  &.size40,
  &.size36,
  &.size32 {
    box-shadow: 0 1px 3px 0 rgba(195, 199, 203, 0.1);
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    background: #f8f9fa;
    box-shadow: none;
  }

  &:not(&.disabled, .loadingBtn):hover {
    border-color: rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
  }
}