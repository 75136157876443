@keyframes text-scroll-v {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-70%);
    }
}

.scrollText {
    p:first-of-type {
        width: auto;
    }

    &:hover {
        p:first-of-type {
            width: fit-content;
            animation-name: text-scroll-v;
            animation-duration: var(--i, 5s);
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-fill-mode: backwards;
        }
    }
}