.customRadioWrapper {
  display: inline-block;
  padding: 12px 0;
}

.customRadio {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #3B3C3C;
  cursor: pointer;

  svg {
    position: relative;
    top: 2px;
  }
}

.unchecked {
  width: 24px;
  height: 24px;
  border: 2px solid #515151;
  border-radius: 50%;
}

.checked {
  width: 24px;
  height: 24px;
  background-color: #2378e5;
  border: 2px solid #2378e5;
  border-radius: 50%;
}