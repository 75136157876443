.messageDrawerContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;

  // margin-right: 30px;
  // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  // overflow: hidden;
  // padding-right: 40px;
}

.messageDrawerContent {
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 390px;
  height: 100%;

  // padding-bottom: 20px;
  overflow: hidden;
  pointer-events: all;
  background: #fff;

  // margin-right: 30px;
  border-radius: 8px 8px 0 0;

  .drawerHeader {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    padding: 28px 16px 10px;
    font-size: 18px;
    font-weight: 600;
    color: #121212;
  }

  .readAll {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #111212;

    /* 137.5% */
    cursor: pointer;

    &:hover {
      color: #121212;
    }

    &[data-disabled="true"] {
      color: #b8b8b8;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .messageWrapper {
    flex: 1;
    padding: 4px 8px;
    overflow: auto;
    background-color: #F8F9FA;

    &::-webkit-scrollbar {
      width: 3px;
      background: rgba(0, 0, 0, 0.10);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      background: rgba(0, 0, 0, 0.10);
      border-radius: 4px;

    }

    // &::-webkit-scrollbar-track {
    //   background-color: #f8f9fa;
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //   background-color: #ddd;
    // }
  }
}

.messageItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 12px;
  background: #fff;
  border: 1px solid #F4F5F6;
  border-radius: 12px;

  &:not(:last-child) {
    border-bottom: 4px solid #f6f6f6;
  }

  .header {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #3B3C3C;
  }

  .titleWrapper {
    display: flex;
    gap: 4px;
  }

  .title {
    display: -webkit-box;
    flex-shrink: 1;
    overflow: hidden;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .content {
    display: -webkit-box;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4B4C4D;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &.visibleAll {
      display: block;
    }

    // why add this?
    // in richtext, there are some html tags in those text, and it's style have been override by global css
    // so should add margin for each element
    >* {
      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }

  .dot {
    flex-shrink: 0;
    width: 6px;
    height: 6px;
    background-color: #fe4a4b;
    border-radius: 50%;
  }

  .img {
    max-width: 100%;
    max-height: 150px;
  }

  .actionArea {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #EBEEF0;

    &:hover {
      color: #1C70EE;
    }
  }

  .seeMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121212;
    text-align: center;
    cursor: pointer;

    svg {
      margin-left: 3px;
    }

    &:hover {
      color: #1C70EE;

      svg {
        path {
          stroke: #1C70EE;
        }

      }
    }
  }
}

.antTabWrapper {
  background-color: #F8F9FA;

  :global {
    .ant-tabs {
      background-color: #fff;
      border-radius: 0 0 16px 16px;


      .ant-tabs-nav {
        padding: 0 16px;
        margin: 0;

        &::before {
          display: none;
        }
      }

      .ant-tabs-nav-list {
        gap: 20px;
      }

      .ant-tabs-tab {
        margin: 0;
        font-size: 14px;
        font-weight: 500 !important;
        color: #515151;
      }

      .ant-tabs-tab-active {
        font-weight: 500;
        color: #1C70EE;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 500;
        color: #1C70EE;
      }

      .ant-tabs-ink-bar {
        height: 3px;
        background: #1C70EE !important;
      }
    }
  }
}

.noMoreText {
  padding: 16px 24px;
  font-size: 15px;
  font-weight: 400;
  color: var(--black-500-b-8-b-8-b-8, #b8b8b8);
  text-align: center;
}