.custom-input-container {
  position: relative;
  display: inline-block;

  .ant-cascader {
    width: 100%;
    height: 44px;
  }

  .ant-select-outlined:not(.ant-select-disabled, .ant-select-customize-input, .ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #212121;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    opacity: 1 !important;

    input {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 44px;
  }

  // .ant-select-focused {
  //   .ant-select-selector {
  //     border: 1px solid #1876d2 !important;
  //   }
  // }

  .ant-select .ant-select-arrow {
    right: 6px;
    color: rgba(0, 0, 0, 0.54);
    opacity: 1;
  }

  .ant-select .ant-select-clear {
    right: 12px;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.87);
  }

  .error {
    margin: 4px 14px 0;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    color: red;
    text-align: left;
  }


}

.floating-label {
  position: absolute;
  top: 12px;
  left: 14px;
  z-index: 10;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #707070;
  pointer-events: none;
  background-color: white;
  transition: all 0.3s ease;

  i {
    padding: 4px 4px 0 0;
    color: red;
  }


}

.custom-input-hasValue .floating-label {
  top: -8px;
  padding: 0 8px;
  font-size: 10px;
  color: #707070;
  background: white;
}


.custom-input-container.focused .floating-label {
  top: -8px;
  padding: 0 8px;
  font-size: 10px;
  color: #1876d2;
  background: white;
}

.custom-input-borderLess {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
    border: none;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
  }

  .ant-select-focused {
    .ant-select-selector {
      border: none !important;
      border-bottom: 1px solid #1876d2 !important;
      box-shadow: unset !important;
    }
  }

  .floating-label {
    top: 11px;
    left: 0;
    padding: 0;
    font-size: 14px;
    color: #707070;
  }

  &.focused .floating-label {
    top: 11px;
    left: 0;
    padding: 0;
    font-size: 14px;
    color: #707070;
  }

  &.custom-input-hasValue {
    .floating-label {
      display: none;
    }
  }

}

.custom-input-popup {
  z-index: 9999 !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;

  .ant-cascader-menu {
    width: 344px;
    max-width: 50vw;
    height: auto !important;
    min-height: 160px;
    max-height: 300px;
  }

  .ant-cascader-menu-item {
    padding: 7px 12px !important;
    font-size: 14px;
    color: #121212;

  }

  .ant-cascader-menu-item[aria-checked="true"] {
    background-color: #e6f4ff;

    .reset-label {
      &::after {
        border: 5px solid #2378E5;
      }
    }

  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background: #F5F7FB !important;
    border-radius: 4px;
  }


  .ant-cascader-menu {
    .reset-label {
      position: relative;
      display: flex;
      padding-left: 22px;
      white-space: pre-wrap;

      &::after {
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        content: ' ';
        border: 2px solid #E8E8EA;
        border-radius: 50%;
      }
    }

    .ant-cascader-menu-item-active {
      .reset-label {
        &::after {
          border: 5px solid #2378E5;
        }
      }
    }
  }

}

.popup-mobile {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  min-height: 100vh;
  background: #FFF;

  .pop-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #121212;
    text-align: center;
    border-bottom: 1px solid #F6F6F6;

    svg {
      position: absolute;
      top: 14px;
      left: 16px;
    }
  }

  .ant-cascader-menu {
    max-height: calc(100vh - 60px);
    padding: 0 !important;
  }

  .ant-cascader-menu-item {
    padding: 10px 14px !important;
  }
}

.popup-mobile-full {
  .ant-cascader-menu {
    width: 100%;
    max-width: 100%;
  }
}