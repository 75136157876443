@import 'styles/variable';
@import 'styles/mixins';

.reportModal {
  border-radius: 12px;

  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 0 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;

    svg {
      cursor: pointer;
    }
  }

  .header {
    display: none;
  }

  .modalContent {
    width: 420px;
    height: 560px;
    border-radius: 12px;
  }
}

.reportModal .modalReportJobConfirmation {
  font-size: 16px;
  color: #111212
}

.reportModal .reportList {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  font-size: 16px;
  line-height: 23px;
  color: #111212;
  font-weight: 600;
  cursor: pointer;
}

.reportModal .modalReportJob {
  padding: 20px;


  h4 {
    margin-bottom: 10px;
    font-size: 22px;
  }


}

.reportModal .back {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 32px;
  margin: 0 6px;
  color: #1C70EE;
  border: 1px solid #1C70EE;
  border-radius: 10px;

  &:hover {
    color: #1C70EE !important;
    background-color: #fff !important;
    border: 1px solid #1C70EE !important;
  }
}

.reportModal .submit {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 32px;
  margin: 0 6px;
  background: #1C70EE;
  border-radius: 10px;

  &:hover {
    background: #0055D4 !important;
  }
}

.bodyContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px;
}

.reportModal .modalReportJobDetail {
  display: flex;
  flex: 1;
  flex-direction: column;
  // margin-top: 20px;
  padding: 0 20px;
  overflow: hidden;
  overflow-y: auto;
}

.reportModal .modalReportJobDetailItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background: transparent;
    border: 1.6px solid #DBDFE4;
    border-radius: 24px;

    svg {
      margin: 0;
    }
  }

  .checked {
    background-color: #1C70EE;
    border-color: #1C70EE;
  }

  .modalReportJobDetailLabel {
    flex: 1;
    margin-left: 10px;
    cursor: pointer;
  }

  .title {
    margin-bottom: 4px;

    /* H5/Heading/Subtitle1-semibold */
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    color: #111212;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4B4C4D;
  }
}