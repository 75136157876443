/* stylelint-disable no-descending-specificity */
@import "styles/variable";
@import "styles/mixins";

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 40px 48px 0;
  color: #86909c;
  background: #202021;
  border-radius: 20px 20px 0 0;

  @media screen and (max-width: 570px) {
    padding: 0
  }

}

.jobFooter {
  @media screen and (max-width: 570px) {
    padding: 48px
  }
}

.footerContainer {
  box-sizing: border-box;
  max-width: 1344px;

  // min-width: calc($maxContentWidth);
  overflow-x: auto;

  @media screen and (max-width: 570px) {
    min-width: 100vw;
  }

  p {
    color: #e4e4e4;
  }

  @include msd {
    padding: 0;
  }
}



.footerMobileContainer {
  //
  padding-bottom: 0;
}

.footerMobileContainerJob {
  padding-bottom: 66px
}

.footerWrapper {
  @include flex;

  margin-top: 25px;
  margin-right: 15%;
  margin-left: 15%;
  color: $black;
}

.footerLink {
  cursor: pointer;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #acaeb1;
    letter-spacing: 0.105px;

    &:hover {
      color: #fff;
    }
  }
}

.footerLinks {
  margin-top: 40px;
}

/* Desktop */
.footerDesktopApp {
  display: flex;
  height: 42px;

  a {
    width: 140px;
    height: 100%;
    margin-right: 20px;
  }
}

.footerDesktopLinkTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
}

.footerDesktopDividerLine {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.1);
}

.footerDesktopWrapper {
  display: block;

  // @include md {
  //   display: block;
  // }
}

.footerDesktopTop {
  // @extend .GlobalContainer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // @include md {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;

  //   // min-width: $maxContentWidth;
  // }
}

.footerDesktopLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // width: 550px;
  margin-right: 60px;

  // @include md {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }
}

.footerDesktopLogo {
  width: 124px;
  height: 32px;
  object-fit: cover;
}

.footerDesktopLeftBottom {
  margin-top: 75px;
}

.footerDesktopCompany {
  margin-top: 24px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #acaeb1;
  }
}

.footerDesktopCompanyDesc {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #acaeb1;
  letter-spacing: 0.105px;
}

.footerDesktopRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 572px;

  // @include md {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  // }
}

.footerDesktopLinkList {
  margin-top: 20px;
  margin-bottom: 0;
  list-style: none;
  -webkit-padding-start: 0;

  li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include msd {
    margin-top: 0;

    li {
      margin-bottom: 32px;
    }
  }
}

.footerMobileLink {
  padding: 24px 20px 0 24px !important;
  list-style: none;
  background: #202021;
  border-radius: 16px 16px 0 0 !important;
  -webkit-padding-start: 0;

  .footerLinkTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
  }

  li {
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footerDesktopBottom {
  // @extend .GlobalContainer;

  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @include md {
    min-width: $maxContentWidth;
  }
}

.footerDesktopSocialWrapper {
  @include md {
    display: flex;
    align-items: center;
  }
}

.footerDesktopSocialDate {
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  text-align: left;
  letter-spacing: 0.105px;
}

.footerDesktopSocialList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 40px;
}

.footerDesktopSocialLinks {
  @include md {
    display: flex;
    flex-direction: column;
  }
}

.footerDesktopSocialLinkFollow {
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.105px;
}

.socialLink {
  position: relative;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;

    .hoverIcon {
      display: inline-block;
    }
  }

  .hoverIcon {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
}

.footerMobileSocialLinks {
  padding: 0 0 16px 24px;
  margin-top: 24px;
}

.footerDownloadApp {
  @include msd {
    margin-top: 40px;
  }
}

.footerDownloadAppText {
  padding-bottom: 20px;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: capitalize;
  letter-spacing: 0.105px;
}

.footerDesktopAppList {
  font-size: 0;

  a {
    display: inline-block;
    height: 42px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  img {
    object-fit: cover;
  }

  a:nth-child(4),
  a:nth-child(5) {
    margin-bottom: 0;
  }
}

.footerMobileCompany {
  margin-top: 32px;
}

.footerMobileSocialDate {
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  text-align: left;
  letter-spacing: 0.105px;
}

.footerMobileSocialList {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.footerMobileSocialLinks {
  display: flex;
  flex-direction: column;
}

.footerMobileSocialLinksText {
  margin-right: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.footerMobileLinkTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  letter-spacing: 0.12px;
}

.footerCopyrightWrapper {
  @include msd {
    padding-top: 30px;
    padding-bottom: 32px;
  }
}

.copyrightCompanies {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.105px;

  span {
    i {
      padding: 0 18px;
      font-style: normal;
    }
  }
}

.footerDesktopLogoLink {
  display: block;
  width: 124px;
  height: 32px;
}