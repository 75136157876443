@import 'styles/variable.scss';
@import 'styles/mixins.scss';

.accordian {
  height: auto;
  transition: height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  &Title {
    // text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &PaddedLine {
    padding: 20px 0;
    // border-bottom: 1px solid #3d4552;
  }

  &Visible {
    display: block;
    overflow: hidden;
  }

  &Hidden {
    height: 0px;
  }

  &ToggleIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      opacity: 0.4;
    }
  }

  &Content {
    // margin-top: 5px;
    transition: height 0.3s ease-in-out;
    &Scroll {
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}
