/* stylelint-disable selector-max-class */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable scss/selector-no-union-class-name */

.desired {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.desiredContainer {
  display: flex;
  flex: 1;
  background-color: #fff;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 12;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background: #fff;
}

.title {
  margin-bottom: 4px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.subtitle {
  padding-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #515151;
  letter-spacing: 0.32px;
}

.text {
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #515151;
  letter-spacing: 0.32px;
}

.form {
  &_item {
    margin-bottom: 24px;
  }

  &_label {
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
  }

  .radioGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 15px;
  }

  &_optional {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #646566;
    text-transform: capitalize;
    letter-spacing: 0.28px;
  }

  &_salary {
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
  }

  &_salaryType {
    flex: 1;
  }

  &_salaryRange {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    gap: 8px;
  }

  &_minSalary {
    flex: 1;
  }

  &_maxSalary {
    flex: 1;
  }
}


.footer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding-top: 8px;
}