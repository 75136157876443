.main {
  padding: 12px 0;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
}

.content {
  display: flex;
  padding: 16px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #3b3c3c;
  text-align: left;
}

.footer {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 16px;
}

.button {
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 10px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: 0;
  border-radius: 10px;
  outline: 0;
  box-shadow: none;
}

.cancel {
  color: #121212;
  background: transparent;
  border: 1px solid #ebeef0;
  box-shadow: 0 1px 3px 0 rgba(195, 199, 203, 0.1);
}

.confirm {
  margin-left: 8px;
  color: #fff;
  background: #1C70EE;
}