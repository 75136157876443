@import "styles/variable.scss";
@import "styles/mixins.scss";

.modal {
  &Overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    height: 101%;
    overflow: hidden;
    /* Follow body's width and height*/
    /* NOTE: vw/vh does not work for IOS Safari as it does not account for browser footer/header */
    /* Instead, we use window.innerHeight. See syncHeight function inside Modal.tsx*/
    // width: 100%;
    // height: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    opacity: 0.5;
  }

  &Wrapper {
    // width: 100%;
    // height: 100%;
    /* Add padding for unsafe areas(curved devices) */
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    position: fixed;
    top: 0;
    left: 0;
    // bottom: 0;
    // right: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch // height: 101%;
      // overflow: hidden;
  }

  &Content {
    display: flex;
    flex-direction: column;
    z-index: 100;
    background: white;
    /* Center Modal content relative to viewport*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 740px;
    max-height: 90vh;
    border-radius: 5px;

    @include sm {
      /* Limit width for mobile devices*/
      width: 100%;
      /* Limit height of Modal for smaller devices */
      max-height: 500px;
      /* center-bottom Modal content relative to viewport*/
      top: unset;
      left: unset;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }

  &ContentFullscreen {
    display: flex;
    flex-direction: column;
    z-index: 100;
    background: white;
    /* Center Modal content relative to viewport*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 740px;
    max-height: 680px;
    border-radius: 5px;

    // If fullscreen is indicated, modal covers entire view port in mobile
    @include sm {
      //height: 85vh !important;
      width: 100%;
      /* center-bottom Modal content relative to viewport*/
      top: unset;
      left: unset;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }

  &Header {
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid rgba($lightgrey, 0.5);
    background-color: $white-1;
    border-radius: 5px;

    &Title {
      flex: auto;
    }
  }

  &Body {
    padding: 16px 24px;
    flex-grow: auto;
    overflow-y: auto;

    @include sm {
      padding: 16px 20px 30px;
    }
  }

  &Footer {
    border-top: 1px solid rgba($lightgrey, 0.5);
    padding: 16px 40px;
    display: flex;
    justify-content: flex-end;
    background-color: white;

    &SecondButtonOnfirm {
      margin-left: 10px;
      background-color: $primaryBlue;
      color: $white-3;

      &:hover {
        box-shadow: $box-shadow-1;
      }
    }

    @include sm {
      button {
        flex: 1;
      }
    }
  }

  &CloseButton {
    margin-top: 3px;
    cursor: pointer;
    z-index: 9999;

    &.pureModalClose {
      position: absolute;
      margin: 0;
      right: 8px;
      top: 8px;
    }
  }
}