//文字
.block {
  display: block;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

//排版
.m {
  margin: 10px !important;
}

.m-t {
  margin-top: 10px !important;
}

.m-b {
  margin-bottom: 10px !important;
}

.m-l {
  margin-left: 10px !important;
}

.m-r {
  margin-right: 10px !important;
}

.m-lg {
  margin: 15px !important;
}

.m-t-lg {
  margin-top: 15px !important;
}

.m-b-lg {
  margin-bottom: 15px !important;
}

.m-l-lg {
  margin-left: 15px !important;
}

.m-r-lg {
  margin-right: 15px !important;
}

.m-hg {
  margin: 20px !important;
}

.m-t-hg {
  margin-top: 20px !important;
}

.m-b-hg {
  margin-bottom: 20px !important;
}

.m-l-hg {
  margin-left: 20px !important;
}

.m-r-hg {
  margin-right: 20px !important;
}

.m-xhg {
  margin: 30px !important;
}

.m-t-xhg {
  margin-top: 30px !important;
}

.m-b-xhg {
  margin-bottom: 30px !important;
}

.m-l-xhg {
  margin-left: 30px !important;
}

.m-r-xhg {
  margin-right: 30px !important;
}

.m-sm {
  margin: 5px !important;
}

.m-t-sm {
  margin-top: 5px !important;
}

.m-b-sm {
  margin-bottom: 5px !important;
}

.m-l-sm {
  margin-left: 5px !important;
}

.m-r-sm {
  margin-right: 5px !important;
}

.m-n {
  margin: 0;
}

.p {
  padding: 10px !important;
}

.p-t {
  padding-top: 10px !important;
}

.p-b {
  padding-bottom: 10px !important;
}

.p-l {
  padding-left: 10px !important;
}

.p-r {
  padding-right: 10px !important;
}

.p-lg {
  padding: 15px !important;
}

.p-t-lg {
  padding-top: 15px !important;
}

.p-b-lg {
  padding-bottom: 15px !important;
}

.p-l-lg {
  padding-left: 15px !important;
}

.p-r-lg {
  padding-right: 15px !important;
}

.p-hg {
  padding: 20px !important;
}

.p-t-hg {
  padding-top: 20px !important;
}

.p-b-hg {
  padding-bottom: 20px !important;
}

.p-l-hg {
  padding-left: 20px !important;
}

.p-r-hg {
  padding-right: 20px !important;
}

.p-sm {
  padding: 5px !important;
}

.p-t-sm {
  padding-top: 5px !important;
}

.p-b-sm {
  padding-bottom: 5px !important;
}

.p-l-sm {
  padding-left: 5px !important;
}

.p-r-sm {
  padding-right: 5px !important;
}

.clear {
  overflow: hidden;
}

.clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}