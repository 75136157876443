/* stylelint-disable selector-max-class */
/* stylelint-disable max-nesting-depth */
@import 'styles/mixins';
@import 'styles/variable';

.vipImage {
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(319deg, rgba(126, 242, 255, 0.25) -1.36%, rgba(152, 245, 255, 0.00) 45.92%), #D7EAFF;
  border-radius: 16px;

  .title {
    width: 260px;
    padding: 24px 0 19px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    text-align: center;
  }

  .btnBox {
    display: flex;
    justify-content: center;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .btn {
    position: absolute;
    top: 33px;
    left: 12px;
    height: 23px;
    font-size: 12px;
    font-weight: 700;
    color: #346ae3;
    text-transform: capitalize;
    background: linear-gradient(91deg, #fff4cb 0%, #ffe272 100%);
    border-radius: 4px;
    box-shadow: none;
  }

  .desc {
    position: absolute;
    top: 63px;
    left: 12px;
    width: 147px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #fff;
  }

  img.image {
    max-width: 100%;
    height: auto;
    padding: 0 12px;
  }
}

.vipShareWrapper {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);

  .vipShareModal {
    position: relative;
    box-sizing: border-box;
    width: 840px;
    height: 560px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 16px;

    .close {
      position: absolute;
      top: 17px;
      right: 15px;
      cursor: pointer;
    }

    h1 {
      margin: 0;
      margin-top: 23px;
      margin-bottom: 10px;
      font-size: 44px;
      font-weight: 700;
      line-height: 1;
    }

    h3 {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
    }

    .main {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .left {
        // padding-right: 47px;
        box-sizing: border-box;
        width: 50%;
        padding: 0 35px;

        .linksBox {
          display: flex;
          justify-content: center;
          padding-bottom: 18px;
        }

        .links {
          box-sizing: border-box;
          display: block;
          width: 262px;
          height: 36px;
          padding: 0 10px;
          overflow: hidden;
          font-size: 17px;
          line-height: 36px;
          color: #2378E5;
          text-decoration-line: underline;
          text-overflow: ellipsis;
          white-space: nowrap;
          background: #E7F4FD;
          border-radius: 8px;
        }

        .blueText {
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 32px;
          color: #000;
          text-align: center;
        }

        .buttonText {
          padding-top: 128px;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 32px;
          color: #000;
          text-align: center;
        }

        .descText {
          padding: 19px 40px 48px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          color: #121212;
          text-align: center;
        }

        .copyButton {
          display: flex;
          gap: 6px;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: #FFF;
          text-align: center;


        }
      }

      .right {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .vipShareWrapper {
    .vipShareModal {
      max-width: 86vw;
      overflow: hidden;
      background-color: #fff;
      border-radius: 14px;

      .main {
        flex-direction: column-reverse;

        .left {
          width: 100%;
          padding: 0 20px 16px;
          background: #fff;

          .copyButton {
            width: 262px;
          }

          .descText {
            padding: 0 20px;
            margin: 0;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #121212;
            text-align: center;
          }

          .buttonText {
            display: flex;
            justify-content: center;
            padding: 16px 0 8px;
            margin: 0;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            color: #121212;
            text-align: center;
            background: none;
          }

          .links {
            width: auto;
            padding: 12px 10px;
            margin-bottom: 20px;
          }
        }

        .right {
          width: 100%;
          height: 64vw;
          background-size: 100%;
          border-radius: 8px 8px 0 0;
        }
      }
    }
  }

}