@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.ButtonWrapper {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    border: 1px solid $lightgrey;
    opacity: 0.7;

    & > img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  &.FacebookButton {
    &:hover {
      border: 1px solid #3c5a99;
    }

  }
  
  &.LinkedinButton {
    &:hover {
      border: 1px solid #0077b5;
    }
  }

  &.GoogleButton {
    &:hover {
      border: 1px solid #ec5245;
    }
  }
}

.SocialButton {
  margin: 10px 18px;
  box-shadow: 0 3px 6px 0 rgba(35, 121, 234, 0.11);
  border: 1px solid $lightgrey;
  border-radius: 50%;
  text-align: center; 
  cursor: pointer;

  @include msd {
    margin: 0 10px;
  }

  &Wrapper {
    @include flex(row, center);
    align-items: center;
    margin-top: 24px;
    height: 45px;
    margin-bottom: 24px;
  }

  & > img {
    vertical-align: middle;
  }
}