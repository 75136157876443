// stylelint-disable
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 20px 24px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
}

.left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 56px;
  overflow: hidden;
}

.title {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121212;
  letter-spacing: 0.34px;
}

.description {
  margin-top: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #7d7d7d;
}

.right {
  flex-shrink: 0;
  max-width: 280px;
  margin-left: 70px;
}

.button {
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 10px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 18px; /* 150% */
  color: #fff;
  text-align: center;
  letter-spacing: 0.14px;
  background: #1c70ee;
  border: 0;
  border-radius: 12px;
  outline: none;
  box-shadow: none;

  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #1c70ee;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  &:hover {
    background: #0d6eff;
  }
}

.arrow {
  flex-shrink: 0;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  cursor: pointer;
}

.mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  background: #fff;
  border-radius: 8px;
}

.mobile_content {
  padding-right: 30px;
}

.mobile_description {
  font-size: 12px;
}

.mobile_icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  flex-shrink: 0;
  width: 91px;
  height: 84px;
}

.mobile_button {
  max-width: 220px;
  margin-top: 16px;
}

.profiled {
  margin-top: 8px;
}

.newMain {
  overflow: hidden;
  position: relative;
  display: flex;
  height: 169px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: linear-gradient(84deg, #fff 37.11%, #e1edff 98.2%);

  .newMain_left {
    display: flex;
    flex: 0 1 auto;
    gap: 4px;
    align-items: flex-start;
    color: #000;
    flex-direction: column;

    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    svg {
      flex: 0 0 20px;
    }

    p {
      color: #6b6b71;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .newMain_right {
    margin-top: 12px;
  }
}

.newMainRow {
  width: calc(50% - 6px);
}

.pTag {
  display: flex;
  gap: 8px;
  align-items: center;
}

.newMainSvg {
  position: absolute;
  right: 0;
  top: 0;
}
