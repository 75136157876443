@import 'styles/mixins';

// ... existing code ...
.maintainNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1080px;
  padding: 14px 48px;
  margin: 0 auto;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #FF9000;
  background-color: #fff9f0;

  @include sm {
    width: 100%;
    min-width: 0;
    padding: 8px 12px;
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 0;
    text-align: center;
    user-select: none;
  }

  .textContainer {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  .text {
    display: -webkit-box;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #ff9000;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: normal;

    @include sm {
      -webkit-line-clamp: 2;
      white-space: normal;
    }
  }

  .viewMoreBtn {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-left: 8px;
    color: #ff9000;
    white-space: nowrap;
    cursor: pointer;
    background: none;

  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  width: 500px;
  max-width: 80vw;
  padding: 32px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);

  .modalContent {
    align-items: center;
    max-height: 250px;
    margin-top: 16px;
    margin-bottom: 20px;
    overflow-y: auto;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    line-height: 28px;
    color: #3B3C3C;
    text-align: left;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 28px;

    .title {
      margin-left: 8px;
      font-family: "SF Pro Display";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: #000;
    }
  }

  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 4px;
    color: #ccc;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}