/* stylelint-disable scss/selector-no-union-class-name */
.jobFunction {
  &_selector {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    padding: 10px 12px;
    margin-bottom: 16px;
    cursor: pointer;
    background: #fff;
    border: 1.2px solid #ebeef0;
    border-radius: 12px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    &:hover {
      border: 1px solid #4096ff;
    }
  }

  &_placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #646566;
  }

  &_value {
    display: block;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_arrow {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}
