.container {
  display: flex;
  // height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #414141;
  // background: #fff;
  margin-bottom: 40px;
  padding: 32px 0;

  .description {
    margin-top: 32px;

    div {
      color: #121212;
      font-size: 20px;

      &.pleaseUseDifferentKeyword {
        font-size: 16px;
        padding-top: 10px;
      }
    }
  }
}
