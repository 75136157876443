@import "styles/variable";
@import "styles/mixins";

.ModalShare {
  &List {
    display: flex;

    //  justify-content: space-around;
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 24px;
    margin: 14px 0 0;
    list-style-type: none;
    border-bottom: 1px solid $lightgrey;

    svg {
      width: 56px;
      height: 56px;
    }
  }

  &Item {
    // margin-right: 70px;
  }

  &ItemLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;

    &::before,
    &::after {
      height: 0;
    }
  }

  &ItemImg,
  svg {
    margin-bottom: 15px;
  }

  &Footer {
    position: relative;
    margin-top: 24px;
  }

  &FooterTooltip {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 10px 5px;
    background-color: $primaryBlue;
    border-radius: 4px;

    &::after {
      position: absolute;
      right: 12px;
      bottom: -6px;
      width: 0;
      height: 0;
      content: "";
      border-color: $primaryBlue transparent transparent transparent;
      border-style: solid;
      border-width: 6.9px 4px 0;
    }
  }

  &FooterLink {
    position: relative;
    padding-right: 45px;
    margin-top: 24px;
    overflow: hidden;
    background-color: #f4f3f3;
    border-radius: 4px;
  }

  &FooterLinkText {
    width: 100%;
    padding: 14px;
    font-size: $mobile-font-size-sm;
    color: $darkgrey;
    white-space: pre;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    @include md {
      font-size: $desktop-font-size-sm;
    }
  }

  &FooterCopy {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 100%;
    background-color: #f4f3f3;

    &:hover {
      cursor: pointer;
    }
  }
}