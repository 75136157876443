@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.MagicLink {
  &_title,
  &_desc {
    text-align: center;

    &_text {
      max-width: 551px;
      color: #353535;
      margin: 0 auto !important;
      text-align: left;
      padding-bottom: 20px;
      padding-top: 20px;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap; 

      @include sm {
        width: 100%;
        text-align: left;
      }
    }

    &_btn {
      max-width: 551px;
      width: 100%;
      height: 44px;
      text-transform: capitalize !important;

      @include sm {
        width: 100%;
      }
    }
  }
}
