@import 'styles/mixins.scss';
@import 'styles/variable.scss';

.text {
  font-weight: 400;
  letter-spacing: 0.5px;

  @include sm {
    letter-spacing: 0.5px;
  }

  &P {
    margin: 1rem 0;
    line-height: 1.4;
  }

  // &H1 {
  //   margin: 0.67rem 0;
  // }

  // &H2 {
  //   margin: 0.75rem 0;
  // }

  // &H3 {
  //   margin: 0.83rem 0;
  // }

  // &H5 {
  //   margin: 1.5rem 0;
  // }

  &XSM {
    font-size: $mobile-font-size-xsm;
    line-height: 12px;
    @include msd {
      font-size: $desktop-font-size-xsm;
    }
    @include md {
      font-size: $desktop-font-size-xsm;
    }
  }

  &SM {
    font-size: $mobile-font-size-sm;
    line-height: 18px;
    @include msd {
      font-size: $desktop-font-size-sm;
    }
    @include md {
      font-size: $desktop-font-size-sm;
    }
  }

  &Base {
    font-size: $mobile-font-size-base;
    line-height: 21px;
    @include msd {
      font-size: $desktop-font-size-base;
    }
    @include md {
      font-size: $desktop-font-size-base;
    }
  }

  &LG {
    font-size: $mobile-font-size-lg;
    line-height: 24px;
    @include msd {
      font-size: $desktop-font-size-lg;
    }
    @include md {
      font-size: $desktop-font-size-lg;
    }
  }

  &XL {
    font-size: $mobile-font-size-xl;
    line-height: 23px;
    @include msd {
      font-size: $desktop-font-size-xl;
    }
    @include md {
      font-size: $desktop-font-size-xl;
    }
  }

  &XXL {
    font-size: $mobile-font-size-xxl;
    line-height: 27px;
    @include md {
      font-size: $desktop-font-size-xxl;
    }
  }

  &XXXL {
    font-size: $mobile-font-size-xxxl;
    line-height: 40px;
    @include md {
      font-size: $desktop-font-size-xxxl;
    }
  }

  &Bold {
    font-weight: 700;
  }

  &Italic {
    font-style: italic;
  }

  &Underline {
    text-decoration: underline;
  }

  &Block {
    display: block;
  }

  &White {
    color: $white-3;
  }

  &PrimaryBlue {
    color: $primaryBlue;
  }

  &Lightgrey {
    color: $lightgrey;
  }

  &Darkgrey {
    color: $darkgrey;
  }

  &Black {
    color: $black;
  }

  &Red {
    color: $red;
  }
}
