#place-list-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(1, 21, 48, 1);
  box-shadow: inset 0 0 5px rgba(1, 21, 48, 1);
  background: rgba(1, 21, 48, 1);
}

#place-list-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 16px;
}

#place-list-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

#place-list-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(193, 193, 193);
}
