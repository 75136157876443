.drawer {
  position: fixed;
  z-index: 1000;
  right: 0px;
  top: 60px;
  height: calc(100vh - 60px - 35px);
  transform: translateX(calc(100% + 30px));
  background: white;
  transition: transform 0.3s ease;
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 15px;
  background-color: #fff;
}

.drawer.open {
  transform: translateX(calc(-28px));
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: opacity 0.3s ease;
  z-index: 998;
}