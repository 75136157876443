.customRadioWrapper {
  display: inline-block;
}

.customRadio {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.unchecked {
  width: 24px;
  height: 24px;
  border: 2px solid #515151;
  border-radius: 50%;
}

.checked {
  width: 24px;
  height: 24px;
  background-color: #2378e5;
  border: 2px solid #2378e5;
  border-radius: 50%;
}

.customRadioDisable {
  color: rgba(0, 0, 0, 0.25);
}
